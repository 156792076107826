import ExpertOrderIntegration from './ExpertOrderIntegration';
import SidesIntegration from './SidesIntegration';
import TablessIntegration from './TablessIntegration';
import WinOrderIntegration from './WinOrderIntegration';

export default {
  ExpertOrderIntegration,
  SidesIntegration,
  TablessIntegration,
  WinOrderIntegration,
};
