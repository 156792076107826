import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import moment from 'moment-timezone';
import 'moment/dist/locale/de';
import 'moment/dist/locale/en-gb';
import en from '../../resources/lang/en';
import de from '../../resources/lang/de';
import LocalizationManager from './LocalizationManager';

const DEFAULT_LANGUAGE = 'de';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  // eslint-disable-next-line no-unused-vars
  init: (_services, _detectorOptions, _i18nextOptions) => {},
  detect: (callback) => {
    const languageFromUrl = LocalizationManager.getLanguageFromUrl();

    if (languageFromUrl) {
      return callback(languageFromUrl);
    }

    const browserLanguage = LocalizationManager.getLanguage();

    if (!browserLanguage) {
      const preferredLanguage = LocalizationManager.getPreferredLanguage();

      if (!preferredLanguage) {
        return callback(DEFAULT_LANGUAGE);
      }

      return callback(preferredLanguage);
    }

    return callback(browserLanguage);
  },
  cacheUserLanguage: (lng) => {
    LocalizationManager.saveLanguage(lng);
    moment.locale(lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      de,
      en,
    },
    react: {
      useSuspense: false,
    },
    fallbackLng: import.meta.PROD ? 'en' : null,
    interpolation: {
      escapeValue: false,
    },
  });
