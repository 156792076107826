import { IntegrationService } from '../../../../../../services/exports/Constants';

export default {
  title: 'Integrations',
  services: {
    [IntegrationService.Tabless]: 'Tabless',
    [IntegrationService.WinOrder]: 'WinOrder',
    [IntegrationService.Sides]: 'Sides',
    [IntegrationService.ExpertOrder]: 'ExpertOrder',
  },
  labels: {
    no_integrations: '{{company}} has no integrations configured.',
  },
  buttons: {
    add_integration: 'Add integration',
  },
  toasts: {
    error: 'Failed to save changes',
    success: 'Changes got saved',
    load_error: 'Failed to load integrations',
  },
};
