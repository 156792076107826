import { IntegrationService } from '../../../../../../services/exports/Constants';

export default {
  title: 'Integrationen',
  services: {
    [IntegrationService.Tabless]: 'Tabless',
    [IntegrationService.WinOrder]: 'WinOrder',
    [IntegrationService.Sides]: 'Sides',
    [IntegrationService.ExpertOrder]: 'ExpertOrder',
  },
  labels: {
    no_integrations: 'Im {{company}} sind keine Integrationen konfiguriert.',
  },
  buttons: {
    add_integration: 'Integration hinzufügen',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
    load_error: 'Integrationen konnten nicht geladen werden',
  },
};
