export default {
  title: 'ExpertOrder',
  form: {
    api_key: {
      label: 'API key',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
  },
};
