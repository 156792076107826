export default {
  title: 'Sides',
  form: {
    subdomain: {
      label: 'Store subdomain',
    },
    client_id: {
      label: 'Client ID',
    },
    client_api_key: {
      label: 'Client api key',
    },
    gateway_api_key: {
      label: 'Gateway api key',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    error: 'Failed to save changes',
    success: 'Changes got saved',
  },
};
