export default {
  title: 'Tabless',
  form: {
    tabless_id: {
      label: 'Tabless ID',
      placeholder: 'Interne ID im tabless-System',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
  },
};
