export default {
  title: 'WinOrder',
  form: {
    store_id: {
      label: 'Store ID: ',
    },
    url: {
      label: 'URL: ',
    },
    username: {
      label: 'Benutzername: ',
      placeholder: 'Benutzername für die Authentifizierung zwischen Ihrem Shop und WinOrder',
      validation: {
        required: 'Benutzername darf nicht leer sein',
        min: 'Mindestlänge für Benutzername beträgt 3 Zeichen',
      },
    },
    password: {
      label: 'Passwort: ',
      placeholder: 'Passwort für die Authentifizierung zwischen Ihrem Shop und WinOrder',
      validation: {
        required: 'Passwort darf nicht leer sein',
        complexity: 'Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Buchstaben enthalten',
      },
    },
  },
  labels: {
    credentials: 'Zugangsdaten:',
  },
  instructions: {
    title: 'Einrichtungsanleitung:',
    step_1: 'Öffnen Sie in WinOrder „Einstellungen” → „Programmeinstellungen“.',
    step_2: 'Gleich oben finden Sie den Schalter „Online-Shop Einrichtung“',
    step_3: 'Im folgenden Fenster finden Sie alle Shops, die in WinOrder vorkonfiguriert sind. Wählen Sie „FoodAmigos“ aus und klicken dann auf „Weiter“.',
    step_4: 'Ersetzen Sie im nächsten Fenster im Feld "URL des Webservice" <0>{{storeid_placeholder}}</0> durch <1>{{company_id}}</1>. <br />Das Endergebnis sollte wie folgt aussehen: <2>{{url}}</2>',
    step_5: 'Geben Sie im selben Fenster <ClickToCopy>{{username}}</ClickToCopy> in das Feld "Benutzername" ein.',
    step_6: 'Geben Sie im selben Fenster <ClickToCopy>{{password}}</ClickToCopy> in das Feld "Kennwort" ein. Klicken Sie dann auf die Schaltfläche "Weiter".',
    step_7: 'WinOrder testet nun die Verbindung zur FoodAmigos-Schnittstelle. Klicken Sie auf "Weiter" und im nächsten Fenster auf "Fertigstellen".',
    step_8: 'Speichern Sie die Einstellungen.',
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
  },
};
