export default {
  title: 'Tabless',
  form: {
    tabless_id: {
      label: 'Tabless ID',
      placeholder: 'Internal ID in tabless system',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    error: 'Failed to save changes',
    success: 'Changes got saved',
  },
};
