export const PAYOUT_STATUSES = {
  created: 'created',
  processing: 'processing',
  paid: 'paid',
  failed: 'failed',
};

export const PAYOUT_PROVIDERS = {
  stripe: 1,
  paypal: 2,
  cash: 3,
};

export const PROMO_CODE_TYPES = {
  single_use: 'single_use',
  multi_use: 'multi_use',
};

export const PROMO_CODE_DISCOUNT_TYPES = {
  fixed_amount: 'fixed_amount',
  percentage: 'percentage',
};

export const PROMO_CODE_TAGS = {
  master: 'master',
  campaign: 'campaign',
  one_time: 'one_time',
};

export const ORDER_METHODS = {
  pickup: 'pickup',
  delivery: 'delivery',
  room_service: 'room_service',
};

export const ORDER_PAYMENT_STATUSES = {
  pending: 'pending',
  partially_paid: 'partially_paid',
  paid: 'paid',
  partially_refunded: 'partially_refunded',
  refunded: 'refunded',
};

export const FULFILLMENT_STATUSES = {
  created: 'created',
  reviewed: 'reviewed',
  accepted: 'accepted',
  preparing: 'preparing',
  ready: 'ready',
  on_delivery: 'on_delivery',
  picked: 'picked',
  rejected: 'rejected',
};

export const CATEGORY_TYPES = {
  DEFAULT: 'default',
  FAVOURITES: 'favourites',
  FREE_ITEMS: 'free_items',
  COMBOS: 'combos',
  MOST_POPULAR: 'most_popular',
};

export const PRODUCT_TYPES = {
  PRODUCT: 'product',
  COMBO: 'combo',
};

export const SERVICE_CHARGE_TYPES = {
  manual: 'manual',
  setup_fee: 'setup_fee',
  subscription: 'subscription',
};

export const SERVICE_CHARGE_STATUSES = {
  pending: 'pending',
  paid: 'paid',
  refunded: 'refunded',
  failed: 'failed',
};

export const INTERNAL_INVOICE_ITEM_STATUSES = {
  pending: 'pending',
  processing: 'processing',
  complete: 'complete',
};

export const SUBSCRIPTION_BILLING_MODES = {
  stripe: 'stripe',
  internal_invoicing: 'internal_invoicing',
};

export const CATEGORY_TYPES_OPTIONS = [
  {
    label: 'Default',
    value: CATEGORY_TYPES.DEFAULT,
  },
  {
    label: 'Favourites',
    value: CATEGORY_TYPES.FAVOURITES,
  },
  {
    label: 'Combos',
    value: CATEGORY_TYPES.COMBOS,
  },
];

export const PROMO_CODE_DISCOUNT_TYPE_CASES = [
  {
    value: PROMO_CODE_DISCOUNT_TYPES.fixed_amount,
    label: 'Fixed amount',
  },
  {
    value: PROMO_CODE_DISCOUNT_TYPES.percentage,
    label: 'Percentage',
  },
];

export const IDENTIFIERS = [
  {
    value: 'vgn',
    label: 'Vegan',
  },
  {
    value: 'vgt',
    label: 'Vegetarian',
  },
  {
    value: 'gf',
    label: 'Gluten free',
  },
  {
    value: 'df',
    label: 'Dairy free',
  },
  {
    value: 'hal',
    label: 'Halal',
  },
  {
    value: 'h&s',
    label: 'Spicy',
  },
];

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const SCHEDULED_ORDERS_TIME_SLOT_INTERVAL_OPTIONS = [5, 10, 15, 20, 30];

export const ORDER_EXTRA_TIME_OPTIONS = [2, 3, 5, 7, 10];

export const ORDER_APPROVAL_TIME_STEP_OPTIONS = [5, 10, 15];

export const LAST_ORDER_GAP_OPTIONS = [0, 5, 15, 30, 45, 60];

export const SCREEN_GROUPS = {
  ONLINE_SHOP: '/online-shop',
  MARKETING: '/marketing',
  RESERVATIONS: '/reservations',
  STOREFRONT: '/storefront',
  ORDERS_PORTAL: '/orders-portal',
  ROI: '/roi',
  SEO: '/seo',
};

export const SCREENS = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CREATE_NEW_PASSWORD: '/create-new-password',
  HOME: `${SCREEN_GROUPS.ONLINE_SHOP}/`,
  PROFILE: `${SCREEN_GROUPS.ONLINE_SHOP}/profile`,
  GET_STARTED: `${SCREEN_GROUPS.ONLINE_SHOP}/get-started`,
  DASHBOARD: `${SCREEN_GROUPS.ONLINE_SHOP}/dashboard`,
  COMPANIES: `${SCREEN_GROUPS.ONLINE_SHOP}/companies`,
  STRIPE: `${SCREEN_GROUPS.ONLINE_SHOP}/stripe`,
  EXTERNAL_DELIVERY_SUPPORT: `${SCREEN_GROUPS.ONLINE_SHOP}/external-delivery-support`,
  FRANCHISES: `${SCREEN_GROUPS.ONLINE_SHOP}/franchises`,
  PAYOUTS: `${SCREEN_GROUPS.ONLINE_SHOP}/payouts`,
  SETUP_PACKAGE_FULFILLMENTS: `${SCREEN_GROUPS.ONLINE_SHOP}/setup-package-fulfillments`,
  STOREFRONT: `${SCREEN_GROUPS.STOREFRONT}/overview`,

  MARKETING_OVERVIEW: `${SCREEN_GROUPS.MARKETING}/overview`,
  MARKETING_FOLLOWERS: `${SCREEN_GROUPS.MARKETING}/followers`,
  MARKETING_EMAIL_MESSAGES: `${SCREEN_GROUPS.MARKETING}/email-messages`,
  MARKETING_EMAIL_TEMPLATE: `${SCREEN_GROUPS.MARKETING}/email-template`,
  MARKETING_PROMO_CODES: `${SCREEN_GROUPS.MARKETING}/promo-codes`,
  MARKETING_GOOGLE_REVIEW_PROGRAM: `${SCREEN_GROUPS.MARKETING}/google-review-program`,
  MARKETING_GOOGLE_BUSINESS: `${SCREEN_GROUPS.MARKETING}/google-business`,
  MARKETING_LOYALTY_PROGRAM: `${SCREEN_GROUPS.MARKETING}/loyalty-program`,
  MARKETING_FLYERS: `${SCREEN_GROUPS.MARKETING}/flyers`,

  RESERVATIONS_OVERVIEW: `${SCREEN_GROUPS.RESERVATIONS}/overview`,
  RESERVATIONS_CREATE: `${SCREEN_GROUPS.RESERVATIONS}/create`,
  RESERVATIONS_SETTINGS: `${SCREEN_GROUPS.RESERVATIONS}/settings`,
  RESERVATIONS_BOOKING_AVAILABILITY: `${SCREEN_GROUPS.RESERVATIONS}/booking-availability`,

  SEO_OVERVIEW: `${SCREEN_GROUPS.SEO}/overview`,

  GOING_LIVE_OVERVIEW: `${SCREEN_GROUPS.ONLINE_SHOP}/going-live-overview`,
  GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS: `${SCREEN_GROUPS.ONLINE_SHOP}/going-live-setup-package-fulfillments`,

  SALES_ORDERS: `${SCREEN_GROUPS.ONLINE_SHOP}/orders`,
  SALES_RECEIPTS: `${SCREEN_GROUPS.ONLINE_SHOP}/receipts`,
  SALES_EXTERNAL_DELIVERIES: `${SCREEN_GROUPS.ONLINE_SHOP}/external-deliveries`,

  BILLING_PAYMENT_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/payment-info`,
  BILLING_MONTHLY_STATEMENTS: `${SCREEN_GROUPS.ONLINE_SHOP}/monthly-statements`,
  BILLING_INVOICES: `${SCREEN_GROUPS.ONLINE_SHOP}/invoices`,
  BILLING_SUBSCRIPTION: `${SCREEN_GROUPS.ONLINE_SHOP}/subscription`,
  BILLING_INTERNAL_INVOICE_ITEMS: `${SCREEN_GROUPS.ONLINE_SHOP}/internal-invoice-items`,
  BILLING_INCOME_REPORTS: `${SCREEN_GROUPS.ONLINE_SHOP}/income-reports`,

  SUBSCRIPTIONS: `${SCREEN_GROUPS.ONLINE_SHOP}/subscriptions`,
  SUBSCRIPTION_PRODUCTS: `${SCREEN_GROUPS.ONLINE_SHOP}/subscription-products`,
  MENU: `${SCREEN_GROUPS.ONLINE_SHOP}/menu`,
  MODIFIERS: `${SCREEN_GROUPS.ONLINE_SHOP}/modifiers`,
  SMART_PRICING: `${SCREEN_GROUPS.ONLINE_SHOP}/smart-pricing`,
  RECOMMENDED_PRODUCTS: `${SCREEN_GROUPS.ONLINE_SHOP}/recommended-products`,
  SOLD_OUT_ITEMS: `${SCREEN_GROUPS.ONLINE_SHOP}/sold-out-items`,
  MENU_IMPORT: `${SCREEN_GROUPS.ONLINE_SHOP}/menu-import`,
  GOOGLE_REVIEWS: `${SCREEN_GROUPS.ONLINE_SHOP}/google-reviews`,

  MY_STORE_SETTINGS: `${SCREEN_GROUPS.ONLINE_SHOP}/settings`,
  MY_STORE_GALLERY: `${SCREEN_GROUPS.ONLINE_SHOP}/gallery`,
  MY_STORE_BUSINESS_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/business-info`,
  MY_STORE_PERSONAL_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/personal-info`,
  MY_STORE_BANK_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/bank-info`,
  MY_STORE_TAX_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/tax-info`,
  MY_STORE_SHIPPING_INFO: `${SCREEN_GROUPS.ONLINE_SHOP}/shipping-info`,
  MY_STORE_GOOGLE_PROFILE: `${SCREEN_GROUPS.ONLINE_SHOP}/google-profile`,

  OPENING_HOURS: `${SCREEN_GROUPS.ONLINE_SHOP}/opening-hours`,
  LOCATIONS: `${SCREEN_GROUPS.ONLINE_SHOP}/locations`,
  TAKEOUT: `${SCREEN_GROUPS.ONLINE_SHOP}/takeout`,
  PAYMENT_METHODS: `${SCREEN_GROUPS.ONLINE_SHOP}/payment-methods`,
  TIP_SETTINGS: `${SCREEN_GROUPS.ONLINE_SHOP}/tip-settings`,
  TERMINAL: `${SCREEN_GROUPS.ONLINE_SHOP}/terminal`,
  RECEIPT_SETTINGS: `${SCREEN_GROUPS.ONLINE_SHOP}/receipt-settings`,
  STAFF_CREDENTIALS: `${SCREEN_GROUPS.ONLINE_SHOP}/staff-credentials`,
  Integrations: `${SCREEN_GROUPS.ONLINE_SHOP}/integrations`,
  LOYALTY_DASHBOARD: `${SCREEN_GROUPS.ONLINE_SHOP}/loyalty-dashboard`,
  LOYALTY_TABLET: `${SCREEN_GROUPS.ONLINE_SHOP}/loyalty-tablet`,
  LOYALTY_TABLET_CREDENTIALS: `${SCREEN_GROUPS.ONLINE_SHOP}/loyalty-tablet-credentials`,
  CONTRACT_AGREEMENT: `${SCREEN_GROUPS.ONLINE_SHOP}/contract-agreement`,
  CONTRACT_TEMPLATE: `${SCREEN_GROUPS.ONLINE_SHOP}/contract-template`,
  NOTIFICATION_PREFERENCES: `${SCREEN_GROUPS.ONLINE_SHOP}/notification-preferences`,
  TEAM_MEMBERS: `${SCREEN_GROUPS.ONLINE_SHOP}/team`,
  TEAM_REPORTS: `${SCREEN_GROUPS.ONLINE_SHOP}/team-reports`,
  SALES_MANAGER_REPORTS: `${SCREEN_GROUPS.ONLINE_SHOP}/sales-manager-reports`,
};

export const COUPON_TYPES = {
  REWARD: 1,
  DEAL: 2,
  PROMO_CODE: 3,
};

export const DELIVERY_ZONE_GEOMETRY_TYPES = {
  CIRCLE: 'circle',
  POLYGON: 'polygon',
  ZIP: 'zip',
};

export const STRIPE_VERIFICATION_STATUS = {
  PENDING: 1,
  REJECTED: 2,
  APPROVED: 3,
};

export const MERCHANT_BUSINESS_TYPE = {
  COMPANY: 'company',
  INDIVIDUAL: 'individual',
};

export const ONBOARDING_STEPS = {
  EMAIL_VERIFICATION: 'email_verification',
  BUSINESS_INFO: 'business_info',
  PERSONAL_INFO: 'personal_info',
  SHIPPING_INFO: 'shipping_info',
  BANK_INFO: 'bank_info',
  TAX_INFO: 'tax_info',
  PERSONAL_DOC: 'personal_doc',
  BUSINESS_DOC: 'business_doc',
  CONFIRM_INFO: 'confirm_info',
  PAYMENT_INFO: 'payment_info',
  CONTRACT: 'contract',
  SUBSCRIPTION: 'subscription',
  PROMO_CODE: 'promo_code',
};

export const MERCHANT_DOCUMENT_PROVIDER_TYPES = {
  STRIPE: 'stripe',
};

export const MERCHANT_DOCUMENT_TYPES = {
  IDENTITY_DOCUMENT: 'identity_document',
  COMPANY_DOCUMENT: 'company_document',
};

export const PAYMENT_METHODS = {
  CARD: 'card',
  PAYPAL: 'paypal',
  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
  SOFORT: 'sofort',
  GIROPAY: 'giropay',
  SEPA_DEBIT: 'sepa_debit',
  CASH: 'cash',
  BACS_DEBIT: 'bacs_debit',
};

export const ONBOARDING_GUIDE_STEPS = {
  Notifications: 'notifications',
  WhatsApp: 'notifications#whatsapp',
  Voice: 'notifications#voice',

  OpenTimesAndLocation: 'open_times_and_location',
  OpenTimes: 'open_times_and_location#open_times',
  Location: 'open_times_and_location#location',

  OrderSettings: 'order_settings',
  PickupTime: 'order_settings#pickup_time',
  DeliveryTime: 'order_settings#delivery_time',
  StaffCredentials: 'order_settings#staff_credentials',

  Menu: 'menu',
  ImportMenu: 'menu#import_menu',
  Favourites: 'menu#favourites',
  SoldOutItems: 'menu#sold_out_items',
  UpsellItems: 'menu#upsell_items',

  Marketing: 'marketing',
  PromoCodes: 'marketing#promo_codes',
  FreeEligibleItems: 'marketing#free_eligible_tiems',

  MyStore: 'my_store',
  Gallery: 'my_store#gallery',
  LogoAndPrimaryColor: 'my_store#logo_and_primary_color',

  GoLive: 'go_live',
  OrderFlyers: 'go_live#order_flyers',
  CreateStorefront: 'go_live#create_storefront',
  EnableLiveOrders: 'go_live#enable_live_orders',

  Complete: 'complete',
};

export const NOTIFICATION_TYPES = {
  STRIPE_VERIFICATION_PENDING: 'App\\Notifications\\Merchant\\Stripe\\StripeVerificationPending',
  STRIPE_VERIFICATION_SUCCESSFUL: 'App\\Notifications\\Merchant\\Stripe\\StripeVerificationSuccessful',
  STRIPE_VERIFICATION_REJECTED: 'App\\Notifications\\Merchant\\Stripe\\StripeVerificationRejected',
};

export const SUBSCRIPTION_PRODUCT_TYPES = {
  plan: 'plan',
  add_on: 'add_on',
};

export const SUBSCRIPTION_PRODUCT_TYPES_OPTIONS = [
  {
    label: 'Plan',
    value: SUBSCRIPTION_PRODUCT_TYPES.plan,
  },
  {
    label: 'Add On',
    value: SUBSCRIPTION_PRODUCT_TYPES.add_on,
  },
];

export const ROLES = {
  admin: 'admin',
  reseller: 'reseller',
  sales_manager: 'sales_manager',
  content_manager: 'content_manager',
  onboarding_manager: 'onboarding_manager',
};

export const PERMISSIONS = {
  manage_payouts: 'manage_payouts',
  enable_daily_payouts: 'enable_daily_payouts',

  delete_menu: 'delete_menu',
  edit_menu_properties: 'edit_menu_properties',

  put_company_live: 'put_company_live',
  change_merchant_email: 'change_merchant_email',
  mark_merchant_email_as_verified: 'mark_merchant_email_as_verified',
  convert_loyalty_program_to_cashback: 'convert_loyalty_program_to_cashback',
  edit_subscription_prices: 'edit_subscription_prices',
  manage_external_delivery: 'manage_external_delivery',
  access_stripe_dashboard: 'access_stripe_dashboard',
  edit_company_access_control_list: 'edit_company_access_control_list',
  create_internal_invoice_items: 'create_internal_invoice_items',

  dashboard_view_restaurant_details_tab: 'dashboard_view_restaurant_details_tab',
  dashboard_view_external_delivery_tab: 'dashboard_view_external_delivery_tab',
};

export const COOKIES = {
  AUTH_TOKEN: `AUTH_TOKEN_${import.meta.env.MODE}`,
  ON_BEHALF_OF_STORE: `ON_BEHALF_OF_STORE_${import.meta.env.MODE}`,
};

export const HELLOPRINT_SERVICE_LEVELS = {
  SAVER: 'saver',
  STANDARD: 'standard',
  EXPRESS: 'express',
};

export const HELLOPRINT_ORDER_STATUSES = {
  ORDER_CREATED: 'ORDER_CREATED',
  DELIVERED: 'DELIVERED',
  SHIPPED: 'SHIPPED',
};

export const SUPPORTED_COUNTRIES = ['DE', 'GB', 'US'];

export const MENU_PROVIDERS = {
  lieferando: 'lieferando',
  wolt: 'wolt',
  deliveroo: 'deliveroo',
  doordash: 'doordash',
  tabless: 'tabless',
  foodamigos: 'foodamigos',
};

export const TAX_BEHAVIOUR = {
  inclusive: 'inclusive',
  exclusive: 'exclusive',
};

export const SCHEDULE_EXCEPTION_TYPES = {
  CLOSED: 'closed',
  OPENED: 'opened',
  PAUSED: 'paused',
};

export const RESERVATION_STATUS_TYPE = {
  PENDING: 0,
  CONFIRMED: 1,
  CANCELED: 2,
  REJECTED: 4,
  UPDATE_PENDING: 5,
  UPDATE_CONFIRMED: 6,
  REMOVED: 7,
};

export const REFUND_REASONS = {
  RequestedByCustomer: 'requested_by_customer',
  Reclamation: 'reclamation',
  CommunicationError: 'communication_error',
  Other: 'other',
};

export const ROOM_SERVICE_LOCATION_TYPES = {
  room_service: 'room_service',
  table_service: 'table_service',
};

export const SCHEDULE_EXCEPTION_CATEGORY_TYPES = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
};

export const RESERVATION_USER_TYPES = {
  MERCHANT: 0,
  CUSTOMER: 1,
};

export const SCREEN_TYPES = {
  DESKTOP: 'Desktop',
  TABLET: 'Tablet',
  MOBILE: 'Mobile',
};

export const FLYER_TYPES = {
  ONE: 1,
  TWO: 2,
};

export const RESTAURANT_TYPES = {
  PIZZA: 'Pizza',
  PIZZA_DELIVERY: 'PizzaDelivery',
  PIZZA_NEAPOLITAN: 'PizzaNeapolitan',
  ITALIAN: 'Italian',
  BURGER: 'Burger',
  TURKISH: 'Turkish',
  ORIENTAL: 'Oriental',
  MEXICAN: 'Mexican',
  SUSHI: 'Sushi',
  JAPANESE: 'Japanese',
  VIETNAMESE: 'Vietnamese',
  THAI: 'Thai',
  ASIAN: 'Asian',
  INDIAN: 'Indian',
  BOWLS: 'Bowls',
  VEGAN: 'Vegan',
  GREEK: 'Greek',
  CAFE: 'Cafe',
  BRUNCH: 'Brunch',
  SPANISH: 'Spanish',
  PERSIAN: 'Persian',
};

export const PAYOUT_INTERVAL = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export const LEGAL_LANGUAGES = {
  GERMAN: 'de',
  ENGLISH: 'en',
};

export const SMART_PRICING_MODIFIER_TYPE = {
  absolute: 'absolute',
  percentage: 'percentage',
  fixed_value: 'fixed_value',
};

export const SMART_PRICING_RESOURCE_TYPE = {
  company: 'App\\Models\\Company',
  menu: 'App\\Models\\Menu',
  category: 'App\\Models\\Category',
  product: 'App\\Models\\Product',
  modifier_group: 'App\\Models\\ModifierGroup',
  modifier: 'App\\Models\\Modifier',
};

export const ENVIRONMENTS = {
  LOCAL: 'localhost',
  DEV: 'development',
  STAGING: 'staging',
  PROD: 'production',
};

export const DeliveryMode = {
  Internal: 'internal',
  External: 'external',
};

export const DeliveryProvider = {
  Standalone: 'standalone',
  FirstDelivery: 'first_delivery',
  WoltDrive: 'wolt_drive',
};

export const DeliveryCarrier = {
  Uber: 'uber',
  Wolt: 'wolt',
};

export const DeliveryStatus = {
  Pending: 'pending',
  RunnerAssigned: 'runner_assigned',
  OnRoutePickup: 'en_route_pickup',
  ArrivedPickup: 'arrived_pickup',
  PickedUp: 'picked_up',
  OnRouteDropoff: 'en_route_dropoff',
  ArrivedDropoff: 'arrived_dropoff',
  Completed: 'completed',
  Cancelled: 'cancelled',
};

export const TEMPLATE_TYPES = {
  STANDARD: 'standard',
  ELEGANT: 'elegant',
  MODERN: 'modern',
  SIMPLE: 'simple',
  MINIMALIST: 'minimalist',
  COOL: 'cool',
  BOLD: 'bold',
  ASIAN: 'asian',
  PERSIAN: 'persian',
  VIBRANT: 'vibrant',
  CHIC: 'chic',
};

export const RESERVATION_MODELS = {
  FRANCHISE: 'Franchise',
};

export const RESERVATION_MEDIA_TAGS = {
  LOGO: 'logo',
  IMAGES: 'images',
};

export const PROJECT_TYPES = {
  ONLINE_SHOP: 'online_shop',
  RESERVATIONS: 'reservations',
  STOREFRONT: 'storefront',
};

export const WEBVIEW_COMMUNICATOR_COMMANDS = {
  NAVIGATE: 'navigate',
  SAVE_LANGUAGE: 'save_language',
  SAVE_WEB_APP_AUTH_TOKEN: 'save_web_app_auth_token',
  REQUEST_ON_BEHALF_OF: 'request_on_behalf_of',
};

export const RN_WEBVIEW_MESSAGE_TYPES = {
  RESERVATION_CREATED: 'reservation_created',
  RESERVATION_CANCELED: 'reservation_canceled',
  RESERVATION_UPDATED: 'reservation_updated',
  RESERVATION_REQUEST_CREATED: 'reservation_request_created',
  RESERVATION_REQUEST_UPDATED: 'reservation_request_updated',
  MOBILE_APP: 'mobile_app',
};

export const SIDEBAR_WIDTH = 350;

export const FLYER_URL_TYPES = {
  ONLINE_SHOP: 'online_shop',
  CUSTOM: 'custom',
};

export const EMAIL_MESSAGE_STATUSES = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  FAILED: 'failed',
};

export const MARKETING_EMAIL_TEMPLATES_TYPES = {
  WELCOME: 'welcome',
  WE_MISS_YOU: 'we_miss_you',
  HAPPY_FRIDAY: 'happy_friday',
  LAZY_SUNDAY: 'lazy_sunday',
  LOYALTY_PROGRAM: 'loyalty_program',
  YOU_MIGHT_ALSO_LIKE: 'you_might_also_like',
  STOREFRONT_PROMO: 'storefront_promo',
};

export const IntegrationService = {
  Tabless: 'tabless',
  WinOrder: 'win_order',
  // Sides: 'sides',
  // ExpertOrder: 'expert_order',
};
